import React, { useState } from "react";
import { sendMessageToTelegram } from "../services/telegram";
import { useMediaQuery } from "react-responsive";

const Form = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  // Состояния для полей формы
  const [formData, setFormData] = useState({
    name: "",
    telefon: "",
    email: "",
    message: "",
  });
  const handleSendMessage = () => {
    const name = formData.name;
    const mobile = formData.telefon;
    const descr = `\nПочта клиента: ${
      formData.email ? formData.email : "нет"
    },\nДоп. сообщение: ${formData.message ? formData.message : "нет"}`;
    sendMessageToTelegram(name, mobile, descr);
  };
  const [errors, setErrors] = useState({}); // Состояния для ошибок
  const [submitted, setSubmitted] = useState(false); // Отметка успешной отправки

  // Обработчик изменения значений в полях
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Валидация формы
  const validate = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Ім'я обов'язкове";
    } else if (formData.name.length < 2) {
      errors.name = "Має бути мінімум 1 символи";
    } else if (formData.name.length > 20) {
      errors.name = "Не більше 20 символів";
    } else if (!/^[A-Za-zА-Яа-яёЁІі\s]+$/.test(formData.name)) {
      errors.name = "Тільки літери";
    }
    if (!formData.telefon.trim()) {
      errors.telefon = "Телефон обов'язковий";
    } else if (!/^\d{10}$/.test(formData.telefon)) {
      errors.telefon = "Введіть корректний телефон";
    } else if (!/^0\d{9}$/.test(formData.telefon)) {
      errors.telefon = "Повинен починатися з 0";
    }
    // if (!formData.email.trim()) {
    //   errors.email = "Email обов'язкове.";
    // } else if (!/^[A-ZА-ЯЁ][a-zа-яё]*$/.test(formData.name)) {
    //   errors.name = "Повинно містити ваша-почта@почтовий.сервіс";
    // }
    // if (!formData.message.trim()) errors.message = "Сообщение обязательно.";
    return errors;
  };
  // Функция для отслеживания конверсий
  const gtag_report_conversion = () => {
    var callback = function () {
      // В случае, если вам не нужно перенаправление, можно оставить пустым
    };
    window.gtag("event", "conversion", {
      send_to: "AW-16803159204/vVjkCNzGnPEZEKS5r8w-", // Замените на ваш идентификатор
      value: 1.0,
      currency: "UAH",
      event_callback: callback,
    });
  };
  // Обработчик отправки формы
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setSubmitted(true);
      console.log("Форма відправлена: ", formData);
      // Очистить форму
      setFormData({ name: "", telefon: "", email: "", message: "" });
      handleSendMessage();
      window.gtag("event", "send_form", { event_name: "submit" });
      gtag_report_conversion();
      setTimeout(() => {
        setSubmitted(false);
      }, 10000);
    }
  };

  return (
    <div>
      {isDesktop && (
        <div className="form">
          <h2>ЗАТЕЛЕФОНУЙТЕ МЕНІ</h2>
          <p>
            Залиште Ваші контактні дані і наш спеціаліст зв'яжеться з Вами
            найближчим часом
          </p>

          <form onSubmit={handleSubmit}>
            <div>
              {errors.name && <p className="error">{errors.name}</p>}
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Ім'я"
              />
            </div>
            <div>
              {errors.telefon && <p className="error">{errors.telefon}</p>}
              <input
                type="tel"
                id="telefon"
                name="telefon"
                value={formData.telefon}
                onChange={handleChange}
                placeholder="Номер телефона"
                maxLength="10"
              />
            </div>
            <div>
              {errors.email && <p className="error">{errors.email}</p>}
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email (не обов'язково)"
              />
            </div>
            <div>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Повідомлення (не обов'язково)"
              ></textarea>
              {errors.message && <p className="error">{errors.message}</p>}
            </div>
            <div id="form-bottom-button">
              <button type="submit">
                {submitted ? (
                  <p className="success">ВІДПРАВЛЕНО</p>
                ) : (
                  <div>НАДІСЛАТИ</div>
                )}
              </button>
            </div>
          </form>
        </div>
      )}
      {isMobile && (
        <div className="form-mobile">
          <div className="form-mobile-top">
            <h2>ЗАТЕЛЕФОНУЙТЕ МЕНІ</h2>
            <p>
              Залиште Ваші контактні дані і наш спеціаліст зв'яжеться з Вами
              найближчим часом.
            </p>
          </div>

          <form onSubmit={handleSubmit}>
            <div>
              {errors.name && <p className="error">{errors.name}</p>}
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Ім'я"
              />
            </div>
            <div>
              {errors.telefon && <p className="error">{errors.telefon}</p>}
              <input
                type="tel"
                id="telefon"
                name="telefon"
                value={formData.telefon}
                onChange={handleChange}
                placeholder="Номер телефона"
                maxLength="10"
              />
            </div>
            <div>
              {errors.email && <p className="error">{errors.email}</p>}
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email (не обов'язково)"
              />
            </div>
            <div>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Повідомлення (не обов'язково)"
              ></textarea>
              {errors.message && <p className="error">{errors.message}</p>}
            </div>
            <div id="form-bottom-button">
              <button type="submit">
                {submitted ? (
                  <p className="success">ВІДПРАВЛЕНО</p>
                ) : (
                  <div>НАДІСЛАТИ</div>
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Form;

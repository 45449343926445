import React, { useState, useEffect } from "react";
import FiltersSection from "../components/FiltersSection";
import ItemSmall from "../components/ItemSmall";
import spinner from "../assets/icons/spinner-solid.svg";
import BottomSections from "../components/BottomSections";
import { useMediaQuery } from "react-responsive";
import vector4 from "../assets/icons/Vector 4.svg";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import SmallForm from "../components/SmallForm";
const Catalog = ({ arrayEstates }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const locationFromHome = useLocation();
  const filtersFromHome = locationFromHome.state?.filters;
  const [dataObjects, setDataObjects] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const [filters, setFilters] = useState({
    city: "Київ",
    borough: "Весь Київ",
    type: "Квартира",
    deal: "Оренда",
    typeOfSort: "default",
    squareFrom: 0,
    squareTo: 0,
    selectedRooms: [],
    floorFrom: 0,
    floorTo: 0,
    isGaz: false,
    canAnimals: false,
    letChildren: false,
    priceFrom: 0,
    priceTo: 0,
  });

  function getPropertyValue(properties, key) {
    return properties[0].property.some((prop) => {
      if (typeof prop === "string") {
        return (
          prop.startsWith(`${key}:`) && prop.split(":")[1].trim() === "true"
        );
      }
      return false; // Если формат другой, можно добавить дополнительную обработку
    });
  }
  // useEffect для частичного обновления фильтров
  useEffect(() => {
    if (filtersFromHome) {
      setFilters((prevFilters) => ({
        ...prevFilters, // Сохраняем все предыдущие фильтры
        city: filtersFromHome.city || prevFilters.city, // Если city из filtersFromHome нет, оставляем старое значение
        borough: filtersFromHome.borough || prevFilters.borough, // То же для borough
        type: filtersFromHome.type || prevFilters.type, // То же для type
        deal: filtersFromHome.deal || prevFilters.deal, // То же для deal
      }));
    }
  }, [filtersFromHome]);

  const filteredData = arrayEstates.filter((estate) => {
    let cityMatch = false;
    let districtMatch = false;
    let dealMatch = false;
    let typeMatch = false;

    let squareMatch = true; // По умолчанию считаем, что площадь подходит
    let roomsMatch = true; // По умолчанию считаем, что количество комнат подходит
    let floorMatch = true; // По умолчанию считаем, что этаж подходит
    let gazMatch = true; // По умолчанию считаем, что газ подходит
    let animalsMatch = true; // По умолчанию считаем, что с животными подходит
    let childrenMatch = true; // По умолчанию считаем, что с детьми подходит
    let priceMatch = true;
    const city = estate.location[0].city[0]._;
    const borough = estate.location[0].borough[0]._;
    const deal = estate.deal[0]._;
    const type = estate.realty_type[0]._;
    const squareTotal = estate.area_total[0];
    const rooms = estate.room_count?.[0] ?? 0; // Количество комнат в объекте
    const price = estate.price[0]._;
    const floor = estate.floor?.[0] ?? null;

    if (filters.city === "Київ") {
      cityMatch = city === "Київ";

      if (filters.borough === "Весь Київ") {
        districtMatch = true;
      } else {
        districtMatch = borough === filters.borough;
      }
    }
    if (filters.city === "Київська область") {
      cityMatch = city !== "Київ";
      if (filters.borough === "Усі міста") {
        districtMatch = true;
      } else {
        districtMatch = borough === filters.borough;
      }
    }
    if (filters.deal === deal) {
      dealMatch = true;
    }
    // Фильтрация по типу недвижимости
    if (filters.type === type) {
      typeMatch = true;
    }

    // Фильтрация по площади
    if (filters.squareFrom > 0 && squareTotal < filters.squareFrom) {
      squareMatch = false;
    }
    if (filters.squareTo > 0 && squareTotal > filters.squareTo) {
      squareMatch = false;
    }
    if (filters.floorFrom > 0 && floor < filters.floorFrom) {
      console.log("OK");

      floorMatch = false;
    }
    if (filters.floorTo > 0 && floor > filters.floorTo) {
      console.log("OK");
      floorMatch = false;
    }
    // Цена
    if (filters.priceFrom > 0 && price < filters.priceFrom) {
      priceMatch = false;
    }
    // Цена
    if (filters.priceTo > 0 && price > filters.priceTo) {
      priceMatch = false;
    }
    // if (filters.selectedRooms.length > 0) {
    //   roomsMatch = filters.selectedRooms.includes(+rooms);
    // } else {
    //   roomsMatch = true;
    // }
    if (filters.selectedRooms.length > 0) {
      if (filters.selectedRooms.includes(5)) {
        // Если в фильтре выбран 5, то показываем все квартиры с комнатами >= 5
        roomsMatch = +rooms >= 5;
      } else {
        // Для остальных случаев проверяем, есть ли точное совпадение
        roomsMatch = filters.selectedRooms.includes(+rooms);
      }
    } else {
      roomsMatch = true; // Если фильтр пустой, показываем все
    }
    return (
      cityMatch &&
      districtMatch &&
      dealMatch &&
      typeMatch &&
      squareMatch &&
      roomsMatch &&
      floorMatch &&
      gazMatch &&
      animalsMatch &&
      childrenMatch &&
      priceMatch
    );
  });
  // Месяцы на украинском языке и их английские аналоги
  const months = {
    "січ.": "Jan",
    "лют.": "Feb",
    "бер.": "Mar",
    "квіт.": "Apr",
    "трав.": "May",
    "черв.": "Jun",
    "лип.": "Jul",
    "серп.": "Aug",
    "вер.": "Sep",
    "жовт.": "Oct",
    "лист.": "Nov",
    "груд.": "Dec",
  };

  // Функция для преобразования даты
  function parseDate(dateStr) {
    // Разбиваем строку на день, месяц и год
    const [day, month, year] = dateStr.split(" ");

    // Формируем строку в формате, который понимает JavaScript
    const formattedDate = `${day} ${months[month]} ${year}`;

    // Возвращаем объект Date
    return new Date(formattedDate);
  }

  // Функция для поиска значения в массиве
  function sortFind(params, find) {
    // Находим элемент в массиве по атрибуту и возвращаем его значение
    const elem = params.find((elem) => elem.$.attribute === `${find}`);

    // Возвращаем значение, если нашли, или null
    return elem ? elem._ : null;
  }

  // Сортировка данных в зависимости от типа сортировки
  const sortData = (data) => {
    switch (filters.typeOfSort) {
      case "low-to-high":
        return data.sort((a, b) => {
          return a.price[0]._ - b.price[0]._; // Сортировка по цене по возрастанию
        });
      case "high-to-low":
        return data.sort((a, b) => {
          return b.price[0]._ - a.price[0]._; // Сортировка по цене по убыванию
        });
      case "date-of-actul":
        return data.sort((a, b) => {
          return new Date(b.updated_at[0]) - new Date(a.updated_at[0]); // Сортировка по дате актуализации
        });
      case "from-old-to-new":
        return data.sort((a, b) => {
          const dateA = sortFind(a.properties[0].property, "property_95");
          const dateB = sortFind(b.properties[0].property, "property_95");
          // Если дата не найдена, считаем элементы одинаковыми
          if (!dateA || !dateB) {
            return 0;
          }

          // Преобразуем строки с датами в объект Date
          const parsedDateA = parseDate(dateA);
          const parsedDateB = parseDate(dateB);

          // Сортируем по дате (по убыванию)
          return parsedDateB - parsedDateA;
        });
      default:
        return data.sort((a, b) => {
          // Получаем даты из свойства property_102
          const dateA = sortFind(a.properties[0].property, "property_102");
          const dateB = sortFind(b.properties[0].property, "property_102");

          // Если дата не найдена, считаем элементы одинаковыми
          if (!dateA || !dateB) {
            return 0;
          }

          // Преобразуем строки с датами в объект Date
          const parsedDateA = parseDate(dateA);
          const parsedDateB = parseDate(dateB);

          // Сортируем по дате (по убыванию)
          return parsedDateB - parsedDateA;
        });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setDataObjects(arrayEstates);
      setIsLoading(false);
    };
    fetchData();
  }, [arrayEstates]);

  // Применяем сортировку к отфильтрованным данным
  const sortedFilteredData = sortData(filteredData);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedFilteredData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleNextPage = () => {
    if (currentItems.length === itemsPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const putDataObjects =
    !isLoading && currentItems
      ? currentItems.map((elem, i) => <ItemSmall key={i} elem={elem} />)
      : null;

  const totalPages = Math.ceil(sortedFilteredData.length / itemsPerPage);

  const getPagination = () => {
    let pages = [];

    pages.push(1);

    if (totalPages > 5) {
      if (currentPage > 3) pages.push("...");
      if (currentPage > 2) pages.push(currentPage - 1);
      pages.push(currentPage);
      if (currentPage < totalPages - 1) pages.push(currentPage + 1);
      if (currentPage < totalPages - 2) pages.push("...");
    } else {
      for (let i = 2; i < totalPages; i++) {
        pages.push(i);
      }
    }

    if (totalPages > 1) pages.push(totalPages);

    return pages;
  };

  return (
    <div>
      {isDesktop && (
        <>
          <Helmet>
            <title>Наші пропозиції</title>
            <meta
              name="description"
              content="Велика різноманітність нерухомості, зі зручної фільтрацією яка допоже швидка та надійно знайти бажане, також якщо не має що знайти, є декілька зручних форм"
            />
          </Helmet>
          <div className="catalog-header">
            <FiltersSection
              upDateFilters={setFilters}
              filters={filters}
            ></FiltersSection>
          </div>

          <div id="catalog">
            <section className="objects">
              {isLoading ? (
                <div className="loading-spinner">
                  <img src={spinner} alt="Loading" className="spinner" />
                </div>
              ) : filteredData.length === 0 ? (
                <div className="no-results">
                  <h2>Нічого не знайдено</h2>
                  <SmallForm
                    formTitleContent={
                      <div>
                        <h2>Нічого не знайшов? Не засмучуйся!</h2>
                        <p>
                          Таке буває, але ти можешь заповнити форму і наші
                          спеціалісти все одно допоможуть тобі.
                        </p>
                      </div>
                    }
                  ></SmallForm>
                </div>
              ) : (
                putDataObjects
              )}
            </section>

            <div className="pagination">
              <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                <img src={vector4} alt="" />
              </button>

              <div className="page-indicator">
                {getPagination().map((page, index) => (
                  <span
                    key={index}
                    className={`page-number ${
                      page === currentPage ? "active" : ""
                    }`}
                    onClick={() => {
                      if (typeof page === "number") setCurrentPage(page);
                    }}
                  >
                    {page}
                  </span>
                ))}
              </div>

              <button
                onClick={handleNextPage}
                disabled={currentItems?.length < itemsPerPage}
              >
                <img src={vector4} alt="" />
              </button>
            </div>
          </div>

          <BottomSections
            sectionFourth={true}
            sectionFive={false}
            sectionReviews={false}
            sectionBottom={true}
            indexOfText="first"
          />
        </>
      )}
      {isMobile && (
        <>
          <Helmet>
            <title>Наші пропозиції</title>
            <meta
              name="description"
              content="Велика різноманітність нерухомості, зі зручної фільтрацією яка допоже швидка та надійно знайти бажане, також якщо не має що знайти, є декілька зручних форм"
            />
          </Helmet>
          <div className="catalog-header">
            <FiltersSection
              upDateFilters={setFilters}
              filters={filters}
            ></FiltersSection>
          </div>

          <div className="catalog-mobile">
            <section className="objects">
              {isLoading ? (
                <div className="loading-spinner">
                  <img src={spinner} alt="Loading" className="spinner" />
                </div>
              ) : filteredData.length === 0 ? (
                <div className="no-results">
                  <h2>Нічого не знайдено</h2>
                  <SmallForm
                    formTitleContent={
                      <div>
                        <h2>Нічого не знайшов? Не засмучуйся!</h2>
                        <p>
                          Таке буває, але ти можешь заповнити форму і наші
                          спеціалісти все одно допоможуть тобі.
                        </p>
                      </div>
                    }
                  ></SmallForm>
                </div>
              ) : (
                putDataObjects
              )}
            </section>

            <div className="pagination">
              <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                <img src={vector4} alt="" />
              </button>

              <div className="page-indicator">
                {getPagination().map((page, index) => (
                  <span
                    key={index}
                    className={`page-number ${
                      page === currentPage ? "active" : ""
                    }`}
                    onClick={() => {
                      if (typeof page === "number") setCurrentPage(page);
                    }}
                  >
                    {page}
                  </span>
                ))}
              </div>

              <button
                onClick={handleNextPage}
                disabled={currentItems?.length < itemsPerPage}
              >
                <img src={vector4} alt="" />
              </button>
            </div>
          </div>

          <BottomSections
            sectionFourth={true}
            sectionFive={false}
            sectionReviews={false}
            sectionBottom={true}
            indexOfText="first"
          />
        </>
      )}
    </div>
  );
};

export default Catalog;

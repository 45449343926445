export const sendMessageToTelegram = async (name, mobile, addDescr) => {
  const botToken = process.env.REACT_APP_BOT_TOKEN;
  const chatIds = process.env.REACT_APP_CHAT_IDS.split(",");
  const message = `Имя: ${name}\nНомер телефона: ${mobile}\nДоп. информация: ${addDescr}`;

  try {
    await Promise.all(
      chatIds.map(async (chatId) => {
        const response = await fetch(
          `https://api.telegram.org/bot${botToken}/sendMessage`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              chat_id: chatId,
              text: message,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Ошибка отправки сообщения в чат ${chatId}: ${response.statusText}`
          );
        }

        const data = await response.json();
        if (data.ok) {
          console.log(`Сообщение отправлено в чат ${chatId}!`);
        } else {
          console.error(
            `Ошибка Telegram API в чате ${chatId}:`,
            data.description
          );
        }
      })
    );
  } catch (err) {
    console.error("Ошибка при отправке сообщения:", err);
  }
};

import "leaflet/dist/leaflet.css";
import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import MapIcon from "../assets/icons/MapIcon.svg";
import { useMediaQuery } from "react-responsive";
const MapComponent = ({ coordinate }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [coordinateMap, setCoordinateMap] = useState([0, 0]);

  const redIcon = new Icon({
    iconUrl: MapIcon,
    iconSize: [32, 32], // Размер иконки
    iconAnchor: [16, 32], // Якорь иконки
    popupAnchor: [0, -32], // Якорь всплывающего окна
  });

  const zoom = 13; // Уменьшено значение для адекватного отображения
  const zoomMobile = 13;
  useEffect(() => {
    if (coordinate?.map_lat && coordinate?.map_lng) {
      setCoordinateMap([coordinate.map_lat[0], coordinate.map_lng[0]]);
    }
  }, [coordinate]); // Добавляем зависимость от координат

  return (
    <div id="mapOn" style={{ marginBottom: "10px" }}>
      {isDesktop && (
        <MapContainer
          center={coordinateMap}
          zoom={zoom}
          style={{ height: "402px", width: "100%", borderRadius: "15px" }}
          key={coordinateMap.toString()} // Ключ для перерисовки карты при изменении координат
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={coordinateMap} icon={redIcon}>
            <Popup>GoogleMap</Popup>
          </Marker>
        </MapContainer>
      )}
      {isMobile && (
        <MapContainer
          center={coordinateMap}
          zoom={zoomMobile}
          style={{ height: "200px", width: "100%", borderRadius: "15px" }}
          key={coordinateMap.toString()} // Ключ для перерисовки карты при изменении координат
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={coordinateMap} icon={redIcon}>
            <Popup>GoogleMap</Popup>
          </Marker>
        </MapContainer>
      )}
    </div>
  );
};

export default MapComponent;

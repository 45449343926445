import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import SmallForm from "./SmallForm";
import logo from "../assets/images/logo dark.svg";
import logoS from "../assets/images/logo dark small.svg";
import vertBtn from "../assets/icons/vertical button.svg";
import Phone from "../assets/icons/Phone.svg";
import { useModal } from "./ModalContext";
import { useMediaQuery } from "react-responsive";

const Header = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [isShrunk, setIsShrunk] = useState(false);

  const { openModal } = useModal();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null); // Реф для меню
  const [startTouch, setStartTouch] = useState(0); // Для отслеживания начала свайпа
  const navigate = useNavigate();
  const handleButtonClick = (path) => {
    // Навигация на другой маршрут
    navigate(`/`);
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen); // Переключение состояния меню
  };
  useEffect(() => {
    // Функция для обновления состояния при прокрутке
    const handleScroll = () => {
      // Если прокрутка больше 100px, меняем состояние
      if (window.scrollY > 10) {
        setIsShrunk(true);
      } else {
        setIsShrunk(false);
      }
    };

    // Добавляем обработчик события прокрутки
    window.addEventListener("scroll", handleScroll);

    // Очистка обработчика при размонтировании компонента
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Пустой массив зависимостей, чтобы использовать только один раз при монтировании

  // Закрытие меню при клике вне меню
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false); // Закрытие меню при клике вне
      }
    };

    // Обработчик свайпов
    const handleTouchStart = (e) => {
      setStartTouch(e.touches[0].clientX); // Запоминаем начальную позицию свайпа
    };

    const handleTouchEnd = (e) => {
      const endTouch = e.changedTouches[0].clientX; // Конечная позиция свайпа
      const swipeThreshold = 50; // Порог для свайпа

      // Если свайп был влево или вправо
      if (
        startTouch - endTouch > swipeThreshold ||
        endTouch - startTouch > swipeThreshold
      ) {
        setIsOpen(false); // Закрытие меню при свайпе
      }
    };

    // Обработчик прокрутки
    const handleScroll = () => {
      setIsOpen(false); // Закрыть меню при прокрутке
    };

    // Добавление слушателей событий
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleTouchStart);
    document.addEventListener("touchend", handleTouchEnd);
    window.addEventListener("scroll", handleScroll); // Добавляем обработчик для прокрутки

    // Удаление слушателей при размонтировании компонента
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchend", handleTouchEnd);
      window.removeEventListener("scroll", handleScroll); // Убираем обработчик для прокрутки
    };
  }, [startTouch]); // Зависимость от состояния startTouch
  // Функция для прокрутки
  function makeActive() {
    const elem = document.querySelector(".section-bottom-left");

    setTimeout(() => {
      elem.classList.add("shake");

      // Удаляем анимацию через 0.5 секунды (по завершении)
      setTimeout(() => {
        elem.classList.remove("shake");
      }, 500);
    }, 1000);
  }
  const scrollToContact = () => {
    // Используем querySelector для получения элемента
    const contactSection = document.querySelector(".section-bottom-left");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth", block: "start" });
      makeActive();
    } else {
      console.error("Элемент с классом .footer-mobile не найден");
    }
  };

  return (
    <div>
      {isDesktop && (
        <header className={`header-desktop ${isShrunk ? "shrink" : ""}`}>
          <nav>
            <Link to="/">Головна</Link>
            <Link to="/dlya-vlasn-neruhomosti">Власникам</Link>
            <Link to="/catalog">Наші пропозиції</Link>
            <Link to="/aboutus">Про нас</Link>
          </nav>
          <div id="logo-container">
            <img
              src={logo}
              alt="Логотип"
              className="logo"
              onClick={handleButtonClick}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div id="header-container">
            <img src={Phone} alt="Иконка телефона" onClick={scrollToContact} />
            <button onClick={scrollToContact}>ЗВ'ЯЗАТИСЯ</button>
          </div>
        </header>
      )}

      {isMobile && (
        <header className="header-mobile">
          <nav className="dropdown-nav">
            <button className="dropdown-button" onClick={toggleMenu}>
              <img src={vertBtn} alt="" />
            </button>
            <ul
              ref={menuRef}
              className={`dropdown-menu ${isOpen ? "open" : ""}`}
            >
              <li>
                <Link to="/">Головна</Link>
              </li>
              <li>
                <Link to="/dlya-vlasn-neruhomosti">Власникам</Link>
              </li>
              <li>
                <Link to="/catalog">Наші пропозиції</Link>
              </li>
              <li>
                <Link to="/aboutus">Про нас</Link>
              </li>
            </ul>
          </nav>
          <div className="logo-container">
            <img
              src={logoS}
              alt="Логотип"
              className="logo"
              onClick={handleButtonClick}
            />
          </div>
          <div className="header-container">
            <img src={Phone} alt="Иконка телефона" onClick={scrollToContact} />
          </div>
        </header>
      )}
    </div>
  );
};

export default Header;

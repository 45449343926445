// Modal.js
import React from "react";
import xmark from "../assets/icons/xmark-solid.svg";
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div id="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          <img src={xmark} alt="" />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;

import React, { useState, useEffect } from "react";
import { sendMessageToTelegram } from "../services/telegram";
const SmallForm = ({ addInf, formTitleContent }) => {
  // Состояния для полей формы
  const [formData, setFormData] = useState({
    name: "",
    telefon: "",
    addInf: "",
  });
  const [formTitle, setFormTitle] = useState(
    <div>
      <h2>Не знаете що обрати?</h2>
      <p>
        Заповніть форму і наші спеціалісти допоможуть Вам з вибором або здати
        Вашу нерухомість
      </p>
    </div>
  );
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      addInf: addInf || "",
    }));
  }, [addInf]);

  useEffect(() => {
    if (formTitleContent) {
      setFormTitle(formTitleContent);
    }
  }, [formTitleContent]);

  const handleSendMessage = () => {
    const name = formData.name;
    const mobile = formData.telefon;
    const addInfo = formData.addInf;
    sendMessageToTelegram(name, mobile, addInfo);
  };
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  // Обработчик изменения значений в полях
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Валидация формы
  const validate = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Ім'я обов'язкове";
    } else if (formData.name.length < 2) {
      errors.name = "Має бути мінімум 1 символи";
    } else if (formData.name.length > 20) {
      errors.name = "Не більше 20 символів";
    } else if (!/^[A-Za-zА-Яа-яёЁІі\s]+$/.test(formData.name)) {
      errors.name = "Тільки літери";
    }
    if (!formData.telefon.trim()) {
      errors.telefon = "Телефон обов'язковий";
    } else if (!/^\d{10}$/.test(formData.telefon)) {
      errors.telefon = "Введіть корректний телефон";
    } else if (!/^0\d{9}$/.test(formData.telefon)) {
      errors.telefon = "Повинен починатися з 0";
    }
    // if (!formData.email.trim()) {
    //   errors.email = "Email обов'язкове.";
    // } else if (!/^[A-ZА-ЯЁ][a-zа-яё]*$/.test(formData.name)) {
    //   errors.name = "Повинно містити ваша-почта@почтовий.сервіс";
    // }
    // if (!formData.message.trim()) errors.message = "Сообщение обязательно.";
    return errors;
  };
  // Функция для отслеживания конверсий
  const gtag_report_conversion = () => {
    var callback = function () {
      // В случае, если вам не нужно перенаправление, можно оставить пустым
    };
    window.gtag("event", "conversion", {
      send_to: "AW-16803159204/vVjkCNzGnPEZEKS5r8w-", // Замените на ваш идентификатор
      value: 1.0,
      currency: "UAH",
      event_callback: callback,
    });
  };
  // Обработчик отправки формы
  // Обработчик отправки формы
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setSubmitted(true);
      handleSendMessage();
      console.log("Форма відправлена: ", formData);
      // Очистить форму
      setFormData({ name: "", telefon: "" });
      window.gtag("event", "send_form", { event_name: "submit" });
      gtag_report_conversion();
      setTimeout(() => {
        setSubmitted(false);
      }, 10000);
    }
  };

  return (
    <div className="small-form">
      {formTitle}
      <form onSubmit={handleSubmit}>
        <div>
          {errors.name && <p className="error">{errors.name}</p>}
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Ім'я"
          />
        </div>
        <div>
          {errors.telefon && <p className="error">{errors.telefon}</p>}
          <input
            type="tel"
            id="telefon"
            name="telefon"
            value={formData.telefon}
            onChange={handleChange}
            placeholder="Номер телефона"
            maxLength="10"
          />
        </div>

        <div id="form-bottom-button">
          <button type="submit">
            {submitted ? (
              <p className="success">ВІДПРАВЛЕНО</p>
            ) : (
              <div>НАДІСЛАТИ</div>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SmallForm;

import LocationImage from "../assets/icons/Location.svg";
import BedImage from "../assets/icons/Bed.svg";
import CityBlock from "../assets/icons/City Block.svg";
import Frame20Image from "../assets/icons/Frame 20.svg";
import { useNavigate } from "react-router-dom";

const ItemSmall = (elem) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/estate/${elem.elem.article}`);
  };

  // Проверка на существование изображений, чтобы не возникала ошибка
  const imageUrl =
    elem.elem.images && elem.elem.images[0]
      ? elem.elem.images[0].image_url[0]
      : null;

  // Проверка на существование объектов внутри массивов
  const location =
    elem.elem.location && elem.elem.location[0] ? elem.elem.location[0] : null;
  const street =
    location && location.street && location.street[0]
      ? location.street[0]._
      : null;
  const houseNum =
    location && location.house_num && location.house_num[0]
      ? location.house_num[0]
      : null;
  const district =
    location && location.district && location.district[0]
      ? location.district[0]._
      : null;
  const borough =
    location && location.borough && location.borough[0]
      ? location.borough[0]._
      : null;

  const updatedAt = (elem.elem?.properties?.[0]?.property ?? [])
    .map((elem) => {
      if (elem.$.attribute === "property_102") {
        return elem._;
      }
      return null; // Если условие не выполняется, возвращаем null
    })
    .find((value) => value !== null); // Ищем первый ненулевой элемент

  const formattedDate =
    updatedAt
      ?.slice(0, 10) // Если значение найдено, обрезаем до 10 символов
      .replace(/-/g, ".") ?? "немає"; // Заменяем дефисы на точки или возвращаем null при отсутствии значения

  const newbuildingName =
    elem.elem.newbuilding_name && elem.elem.newbuilding_name[0]
      ? elem.elem.newbuilding_name[0].slice(3)
      : null;

  const price =
    elem.elem.price && elem.elem.price[0] ? elem.elem.price[0]._ : null;
  const priceCurrency =
    elem.elem.price && elem.elem.price[0] && elem.elem.price[0].$
      ? elem.elem.price[0].$.currency
      : null;

  const roomCount =
    elem.elem.room_count && elem.elem.room_count[0]
      ? elem.elem.room_count[0]
      : null;
  const areaTotal =
    elem.elem.area_total && elem.elem.area_total[0]
      ? elem.elem.area_total[0]
      : null;
  const floor =
    elem.elem.floor && elem.elem.floor[0] ? elem.elem.floor[0] : null;
  const totalFloors =
    elem.elem.total_floors && elem.elem.total_floors[0]
      ? elem.elem.total_floors[0]
      : null;

  return (
    <div className="object" onClick={handleClick}>
      {imageUrl ? <img src={imageUrl} alt="" className="front-image" /> : null}
      <div className="object-decrt">
        <div className="elements">
          {updatedAt && (
            <div className="data-of-actualize">
              <p className="small-text-variable">Дата актуалізації:</p>{" "}
              <p>{updatedAt}</p>
            </div>
          )}
          {street && houseNum && (
            <div className="name">
              ВУЛ. {street} {houseNum}
            </div>
          )}
          {district && borough && (
            <div className="location">
              <img src={LocationImage} alt="" />
              {district}, {borough}
            </div>
          )}
          {newbuildingName ? (
            <div className="borough">
              <div>ЖК:</div> <div>{newbuildingName}</div>
            </div>
          ) : null}
          {price && (
            <div className="price">
              <p>Ціна: </p>
              <p className="price-count">
                {price} {priceCurrency}
              </p>
            </div>
          )}
        </div>
        <div className="additional">
          {roomCount && (
            <div>
              <img src={BedImage} alt="" />
              {roomCount}x
            </div>
          )}
          {areaTotal && (
            <div>
              <img src={Frame20Image} alt="" />
              {areaTotal} m²
            </div>
          )}
          {floor && (
            <div>
              <img src={CityBlock} alt="" />
              {floor}/{totalFloors}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemSmall;

import popularman from "../assets/images/Popular Man.svg";
import Carousel from "../components/Carousel";
import Form from "../components/Form";
import instagram from "../assets/icons/instagram.svg";
import telegram from "../assets/icons/telegram-brands-solid.svg";
import tiktok from "../assets/icons/tiktok-brands-solid.svg";
import frame63 from "../assets/images/Frame 63.svg";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import SmallForm from "../components/SmallForm";
import { useModal } from "../components/ModalContext";

const BottomSections = ({
  sectionFourth,
  sectionFive,
  sectionReviews,
  sectionBottom,
  indexOfText,
}) => {
  const { openModal } = useModal();
  const getLook = () => {
    return openModal(
      <SmallForm
        formTitleContent={
          <div>
            <h2>Шлях до мрії</h2>
            <p>Наші фахівці зв'яжуться з вами, та допоможуть розпочати шлях.</p>
          </div>
        }
      />
    );
  };

  const handleLink = (url) => {
    window.location.href = `${url}`;
  };
  // const data = [
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  // ];
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return (
    <div>
      {isDesktop && (
        <div className="bottom-sections">
          {sectionFourth ? (
            <section className="section-fourth">
              <h2>ВАШ ШЛЯХ ДО УГОДИ РАЗОМ З DEALINE</h2>
              {indexOfText === "first" ? (
                <div className="container">
                  <div className="container-blocks">
                    <div className="block">01 / Дзвінок</div>
                    <div className="block">02 / Огляд об'єкту</div>
                    <div className="block">
                      03 / Пошук покупця або нерухомості
                    </div>
                    <div className="block">04 / Заключення договору</div>
                  </div>
                  <div className="line">
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </div>
                  <div className="container-texts">
                    <p>
                      Розмова для уточнення деталей і вибору часу зустрічі чи
                      перегляду.
                    </p>
                    <p>
                      Огляд квартири, будинку або приміщення з експертом
                      компанії.
                    </p>
                    <p>
                      Підбір нерухомості або орендарів/покупців. В залежності
                      від Вашого запиту.
                    </p>
                    <p>
                      Підписання договору оренди. Або початок процесу
                      купівлі-продажу нерухомого майна.
                    </p>
                  </div>
                  <div className="section-fourth-button">
                    <button onClick={getLook}>
                      РОЗПОЧАТИ СВІЙ ШЛЯХ ДО УГОДИ <img src={frame63} alt="" />
                    </button>
                  </div>
                </div>
              ) : null}
              {indexOfText === "second" ? (
                <div className="container">
                  <div className="container-blocks">
                    <div className="block">01 / Дзвінок або Заява</div>
                    <div className="block">02 / Підбір варіантів</div>
                    <div className="block">03 / Перегляди</div>
                    <div className="block">04 / Заключення договору</div>
                  </div>
                  <div className="line">
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </div>
                  <div className="container-texts">
                    <p>
                      Розкажіть нам про Ваші побажання в житлі, а ми знайдемо
                      його для Вас.
                    </p>
                    <p>
                      Наш експерт підбере найкращі варіанти, що влаштовують Ваші
                      потреби.
                    </p>
                    <p>
                      Перегляд варіантів які Вас сподобались, уточнення Ваших
                      вподобань.
                    </p>
                    <p>
                      Купівля або оренда квартири, будинку, приміщення тощо. Що
                      повністю відповідає Вашим побажанням.
                    </p>
                  </div>
                  <div className="section-fourth-button">
                    <button onClick={getLook}>
                      РОЗПОЧАТИ СВІЙ ШЛЯХ ДО УГОДИ <img src={frame63} alt="" />
                    </button>
                  </div>
                </div>
              ) : null}
            </section>
          ) : null}
          {sectionFive ? (
            <section className="section-five">
              <h2>DEALINE ВАШ ПАРТНЕР ДО УСПІШНОЇ УГОДИ</h2>

              <ul>
                <li>
                  <img src={popularman} alt="" />
                  <h3>ВІДКРИТІСТЬ</h3>
                  <p>
                    Працюючи з нами, Ви завжди знаєте, як ведеться робота,
                    відчуваєте турботу і маєте чітке розуміння процесів
                  </p>
                </li>
                <li>
                  <img src={popularman} alt="" />
                  <h3>ЧЕСТНІСТЬ</h3>
                  <p>
                    Співпрацюючи з нами, ви впевнені, що отримуєте інформацію,
                    яка на 100% відповідає дійсності
                  </p>
                </li>
                <li>
                  <img src={popularman} alt="" />
                  <h3>ЕКСПЕРТНІСТЬ</h3>
                  <p>
                    Ми маємо багаторічний досвід роботи, а також глибокі знання
                    ринку, знаємо все про купівлю, продаж та оренду нерухомості
                  </p>
                </li>
              </ul>
            </section>
          ) : null}
          {/* {sectionReviews ? (
          <section className="section-reviews">
            <h2>ЩО КАЖУТЬ НАШІ КЛІЄНТИ</h2>
            <Carousel data={data}></Carousel>
          </section>
        ) : null} */}
          {sectionBottom ? (
            <section className="section-bottom">
              <div className="section-bottom-left">
                <h2>НАШІ КОНТАКТИ</h2>
                <h4>НОМЕРИ ТЕЛЕФОНУ</h4>
                <ul>
                  <li>+380960261765</li>
                  <li>+380981016035</li>
                </ul>
                <h4>E-MAIL</h4>
                <a href="mailto:mail@dealine.com">support@dealine.com.ua</a>
                <h4>НАШІ СОЦМЕРЕЖІ</h4>
                <div className="container-bottom-icons">
                  {/* <img src={instagram} alt="" /> */}
                  <img
                    src={telegram}
                    alt=""
                    onClick={() => handleLink("https://t.me/dealine_kiev")}
                  />

                  <img
                    src={tiktok}
                    alt=""
                    onClick={() =>
                      handleLink(
                        "https://www.tiktok.com/@dealine_ua?is_from_webapp=1&sender_device=pc"
                      )
                    }
                  />
                </div>
              </div>
              <div className="section-bottom-right">
                <Form></Form>
              </div>
            </section>
          ) : null}
        </div>
      )}
      {isMobile && (
        <div className="bottom-sections-mobile">
          {sectionFourth ? (
            <section className="section-fourth">
              <h2>ВАШ ШЛЯХ ДО УГОДИ РАЗОМ З DEALINE</h2>
              <div className="container">
                <div className="container-blocks">
                  <div className="block">01 / Дзвінок</div>
                  <div className="block">02 / Огляд об'єкту</div>
                  <div className="block">
                    03 / Пошук покупця або нерухомості
                  </div>
                  <div className="block">04 / Заключення договору</div>
                </div>
                <div className="line">
                  <div className="circle"></div>
                  <div className="circle"></div>
                  <div className="circle"></div>
                  <div className="circle"></div>
                </div>
                <div className="container-texts">
                  <p>
                    Розмова для уточнення деталей і вибору часу зустрічі чи
                    перегляду.
                  </p>
                  <p>
                    Огляд квартири, будинку або приміщення з експертом компанії.
                  </p>
                  <p>
                    Підбір нерухомості або орендарів/покупців. В залежності від
                    Вашого запиту.
                  </p>
                  <p>
                    Підписання договору оренди. Або початок процесу
                    купівлі-продажу нерухомого майна.
                  </p>
                </div>
              </div>
              <div className="section-fourth-button">
                <button onClick={getLook}>
                  РОЗПОЧАТИ СВІЙ ШЛЯХ ДО УГОДИ <img src={frame63} alt="" />
                </button>
              </div>
            </section>
          ) : null}
          {sectionFive ? (
            <section className="section-five ">
              <h2>DEALINE ВАШ ПАРТНЕР ДО УСПІШНОЇ УГОДИ</h2>

              <ul>
                <li>
                  <img src={popularman} alt="" />
                  <h3>ВІДКРИТІСТЬ</h3>
                  <p className="small-text-variable">
                    Працюючи з нами, Ви завжди знаєте, як ведеться робота,
                    відчуваєте турботу і маєте чітке розуміння процесів
                  </p>
                </li>
                <li>
                  <img src={popularman} alt="" />
                  <h3>ЧЕСТНІСТЬ</h3>
                  <p className="small-text-variable">
                    Співпрацюючи з нами, ви впевнені, що отримуєте інформацію,
                    яка на 100% відповідає дійсності
                  </p>
                </li>
                <li>
                  <img src={popularman} alt="" />
                  <h3>ЕКСПЕРТНІСТЬ</h3>
                  <p className="small-text-variable">
                    Ми маємо багаторічний досвід роботи, а також глибокі знання
                    ринку, знаємо все про купівлю, продаж та оренду нерухомості
                  </p>
                </li>
              </ul>
            </section>
          ) : null}
          {/* {sectionReviews ? (
          <section className="section-reviews">
            <h2>ЩО КАЖУТЬ НАШІ КЛІЄНТИ</h2>
            <Carousel data={data}></Carousel>
          </section>
        ) : null} */}
          {sectionBottom ? (
            <section className="section-bottom">
              <div className="section-bottom-left">
                <h2>НАШІ КОНТАКТИ</h2>
                <h4>НОМЕРИ ТЕЛЕФОНУ</h4>
                <ul>
                  <li>
                    <a href="tel:+380960261765">+380960261765</a>
                  </li>
                  <li>
                    <a href="tel:+380960261765">+380981016035</a>
                  </li>
                </ul>
                <h4>E-MAIL</h4>
                <a href="mailto:mail@dealine.com">support@dealine.com.ua</a>
                <h4>НАШІ СОЦМЕРЕЖІ</h4>
                <div className="container-bottom-icons">
                  <img
                    src={telegram}
                    alt=""
                    onClick={() => handleLink("https://t.me/dealine_kiev")}
                  />
                  <img
                    src={tiktok}
                    alt=""
                    onClick={() =>
                      handleLink(
                        "https://www.tiktok.com/@dealine_ua?is_from_webapp=1&sender_device=pc"
                      )
                    }
                  />
                </div>
              </div>
              <div className="section-bottom-right">
                <Form></Form>
              </div>
            </section>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default BottomSections;

import logo from "../assets/images/logo dark.svg";
import phone from "../assets/images/Phone.svg";
import email from "../assets/images/Email.svg";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Footer = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return (
    <div>
      {isDesktop && (
        <footer className="footer">
          <div className="footer-left-container">
            <img src={logo} alt="" />
            <p>© Dealine. Усі права захищено</p>
          </div>
          <div className="footer-center-container">
            <Link to="/">Головна</Link>
            <Link to="/catalog">Оренда житла</Link>
            <Link to="/catalog">Наші пропозиції</Link>
            <Link to="/aboutus">Про нас</Link>
          </div>
          <div className="footer-right-container">
            <a href="tel:+380960261765">
              <img src={phone} alt="телефон" />
            </a>
            <a href="mailto:support@dealine.com">
              <img src={email} alt="імейл" />
            </a>
          </div>
        </footer>
      )}
      {isMobile && (
        <footer className="footer-mobile">
          <div className="footer-left-container">
            <img src={logo} alt="" />
            <p>© Dealine. Усі права захищено</p>
          </div>
          <div className="footer-mobile-both">
            <div className="footer-center-container">
              <Link to="/">Головна</Link>
              <Link to="/catalog">Оренда житла</Link>
              <Link to="/catalog">Наші пропозиції</Link>
              <Link to="/aboutus">Про нас</Link>
            </div>

            <div className="footer-right-container">
              <a href="tel:+380960261765">
                <img src={phone} alt="телефон" />
              </a>

              <a href="mailto:support@dealine.com">
                <img src={email} alt="імейл" />
              </a>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
};

export default Footer;

import frame67 from "../assets/icons/Frame 67.svg";
import image3 from "../assets/images/image-3.webp";
import image4 from "../assets/images/image 4.svg";
import rect4 from "../assets/images/Rectangle 4.svg";
import BottomSections from "../components/BottomSections";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import SmallForm from "../components/SmallForm";
import { useModal } from "../components/ModalContext";
const AboutUs = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const navigate = useNavigate();
  const { openModal } = useModal();
  const handleButtonClick = (path) => {
    // Навигация на другой маршрут
    navigate(`/${path}`);
  };
  const getLook = () => {
    return openModal(
      <SmallForm
        formTitleContent={
          <div>
            <h2>Шлях до мрії</h2>
            <p>Наші фахівці зв'яжуться з вами, та допоможуть розпочати шлях.</p>
          </div>
        }
      />
    );
  };
  return (
    <>
      {isDesktop && (
        <div id="aboutus">
          <Helmet>
            <title>Про нас</title>
            <meta
              name="description"
              content="Більше інформації про нас, наші можливості та переваги"
            />
          </Helmet>
          <section className="aboutus-top">
            <div>
              <span className="main-title-text-big big-text-variable">
                БІЛЬШЕ, НІЖ ПРОСТО НЕРУХОМІСТЬ
              </span>
              <img
                src={frame67}
                alt=""
                className="main-title-button"
                onClick={getLook}
              />
            </div>
            <div>
              <p className="main-title-text-small">
                Будинки роблять стіни й дахи, але дім створюють мрії.
              </p>
              <span
                className="main-title-text-big big-text-variable"
                style={{ marginLeft: "20px" }}
              >
                ВАШ НОВИЙ СТИЛЬ ЖИТТЯ
              </span>
            </div>
          </section>
          <img src={image3} alt="" style={{ height: "100%", width: "100%" }} />
          <section className="aboutus-middle">
            <div>
              <h3>НАШІ ЦІННОСТІ</h3>
              <div>
                <img src={rect4} alt="" />
                <p>
                  Ми віримо, що кожен має право на житло, яке відображає його
                  унікальність та підходить до способу життя. У нашому агентстві
                  нерухомості головний пріоритет – задоволення потреб клієнтів і
                  створення простору, де можна жити, рости та мріяти. Чесність,
                  професіоналізм і турбота про деталі – основа нашої роботи.
                </p>
              </div>
            </div>
            <div>
              <h3>ЩО МИ ПРОПОНУЄМО</h3>
              <div>
                <img src={rect4} alt="" />
                <p>
                  Наша команда займається підбором та пошуком нерухомості для
                  кожного клієнта, враховуючи всі побажання. Ми працюємо з
                  квартирами, будинками, комерційною нерухомістю та іншими
                  типами приміщень, тому можемо запропонувати різноманітний
                  вибір, незалежно від ваших цілей та бюджету.
                </p>
              </div>
            </div>
          </section>
          <section className="aboutus-bottom">
            <div className="aboutus-bottom-left">
              <h3>ЧОМУ ВАРТО ОБРАТИ САМЕ НАС</h3>
              <p>
                Увага до деталей. Ми враховуємо всі аспекти вашої ситуації: чи
                це бажання продати за найкращою ціною, здати в оренду на
                вигідних умовах, чи знайти житло, що відповідатиме вашим мріям.
              </p>
              <ul>
                <li>
                  <img src={rect4} alt="" />
                  Індивідуальний підхід. Ми уважно слухаємо ваші потреби,
                  аналізуємо запити та пропонуємо рішення, які ідеально
                  підійдуть саме вам
                </li>
                <li>
                  <img src={rect4} alt="" />
                  Повага до клієнтів. Ми розуміємо, що кожна ситуація унікальна,
                  і ставимо ваші інтереси на перше місце, допомагаючи прийняти
                  найкраще рішення.
                </li>
                <li>
                  <img src={rect4} alt="" />
                  Чесність і відкритість. Ми цінуємо довіру наших клієнтів і
                  завжди надаємо повну інформацію про об’єкт та умови угоди. Без
                  прихованих комісій та сюрпризів.
                </li>
              </ul>
            </div>
            <img src={image4} alt="" />
          </section>
          <BottomSections
            sectionFourth={true}
            sectionFive={true}
            sectionReviews={false}
            sectionBottom={true}
            indexOfText="first"
          ></BottomSections>
        </div>
      )}
      {isMobile && (
        <div className="aboutus-mobile">
          <Helmet>
            <title>Про нас</title>
            <meta
              name="description"
              content="Більше інформації про нас, наші можливості та переваги"
            />
          </Helmet>
          <section className="aboutus-top">
            <div>
              <span className="main-title-text-big ">
                <p className="big-text-variable">
                  БІЛЬШЕ, НІЖ ПРОСТО НЕРУХОМІСТЬ - ВАШ НОВИЙ СТИЛЬ ЖИТТЯ
                </p>
              </span>
            </div>
            <div>
              <p className="main-title-text-small">
                Будинки роблять стіни й дахи, але дім створюють мрії.
              </p>
            </div>
          </section>
          <img src={image3} alt="" style={{ height: "100%", width: "100%" }} />
          <section className="aboutus-middle">
            <div>
              <h3>НАШІ ЦІННОСТІ</h3>
              <div>
                <img src={rect4} alt="" />
                <p>
                  Ми віримо, що кожен має право на житло, яке відображає його
                  унікальність та підходить до способу життя. У нашому агентстві
                  нерухомості головний пріоритет – задоволення потреб клієнтів і
                  створення простору, де можна жити, рости та мріяти. Чесність,
                  професіоналізм і турбота про деталі – основа нашої роботи.
                </p>
              </div>
            </div>
            <div>
              <h3>ЩО МИ ПРОПОНУЄМО</h3>
              <div>
                <img src={rect4} alt="" />
                <p>
                  Наша команда займається підбором та пошуком нерухомості для
                  кожного клієнта, враховуючи всі побажання. Ми працюємо з
                  квартирами, будинками, комерційною нерухомістю та іншими
                  типами приміщень, тому можемо запропонувати різноманітний
                  вибір, незалежно від ваших цілей та бюджету.
                </p>
              </div>
            </div>
          </section>
          <section className="aboutus-bottom">
            <div className="aboutus-bottom-left">
              <h3>ЧОМУ ВАРТО ОБРАТИ САМЕ НАС</h3>
              <p>
                Увага до деталей. Ми враховуємо всі аспекти вашої ситуації: чи
                це бажання продати за найкращою ціною, здати в оренду на
                вигідних умовах, чи знайти житло, що відповідатиме вашим мріям.
              </p>
              <ul>
                <li>
                  <img src={rect4} alt="" />
                  Індивідуальний підхід. Ми уважно слухаємо ваші потреби,
                  аналізуємо запити та пропонуємо рішення, які ідеально
                  підійдуть саме вам
                </li>
                <li>
                  <img src={rect4} alt="" />
                  Повага до клієнтів. Ми розуміємо, що кожна ситуація унікальна,
                  і ставимо ваші інтереси на перше місце, допомагаючи прийняти
                  найкраще рішення.
                </li>
                <li>
                  <img src={rect4} alt="" />
                  Чесність і відкритість. Ми цінуємо довіру наших клієнтів і
                  завжди надаємо повну інформацію про об’єкт та умови угоди. Без
                  прихованих комісій та сюрпризів.
                </li>
              </ul>
            </div>
            <img src={image4} alt="" />
          </section>
          <BottomSections
            sectionFourth={true}
            sectionFive={true}
            sectionReviews={false}
            sectionBottom={true}
            indexOfText="first"
          ></BottomSections>
        </div>
      )}
    </>
  );
};

export default AboutUs;

const PolicityAndPrivaty = () => {
  return (
    <div className="policity-and-privaty">
      <h2>Політика конфіденційності</h2>
      <p>Дата набуття чинності: 06.12.2024</p>
      <p>
        Ця Політика конфіденційності (далі &mdash; &laquo;Політика&raquo;)
        описує, як Dealine збирає, використовує, обробляє та захищає вашу
        інформацію при використанні нашого веб-сайту dealine.com.ua, та інших
        сервісів (&laquo;Сервіси&raquo;).
      </p>
      <p>1. Збір інформації</p>
      <p>Ми можемо збирати таку інформацію:</p>
      <p>1.1. Особиста інформація:</p>
      <p>Ім'я, прізвище;</p>
      <p>Контактні дані (електронна пошта, номер телефону);</p>
      <p>Інші дані, які ви надаєте добровільно.</p>
      <p>1.2. Технічна інформація:</p>
      <p>IP-адреса;</p>
      <p>Дані про пристрій (тип пристрою, операційна система, браузер);</p>
      <p>Файли cookie та інші технології відстеження;</p>
      <p>Логи відвідувань.</p>
      <p>
        1.3. Інформація зі сторонніх джерел: Ми можемо отримувати інформацію про
        вас від третіх осіб, таких як партнери або соціальні мережі, якщо ви
        взаємодієте з нами через їх платформи.
      </p>
      <p>2. Використання інформації</p>
      <p>Ми використовуємо зібрану інформацію для наступних цілей:</p>
      <p>Забезпечення роботи та покращення наших Сервісів;</p>
      <p>Виконання договірних зобов'язань перед вами;</p>
      <p>Персоналізація вашого досвіду;</p>
      <p>
        Зв'язок з вами, включаючи розсилку новин та рекламних матеріалів (за
        вашою згодою);
      </p>
      <p>Аналіз та покращення нашої маркетингової стратегії;</p>
      <p>як Дотримання законів та захист прав Компанії.</p>
      <p>3. Обмін інформацією</p>
      <p>
        Ми можемо передавати вашу інформацію наступним категоріям третіх осіб:
      </p>
      <p>Партнерам та підрядникам для забезпечення роботи Сервісів;</p>
      <p>Платіжним системам та фінансовим організаціям;</p>
      <p>Державним органам, якщо це вимагається законодавством;</p>
      <p>Іншим третім особам за вашою згодою.</p>
      <p>4. Захист інформації</p>
      <p>
        Ми вживаємо розумних заходів для захисту вашої інформації від
        несанкціонованого доступу, зміни, розкриття чи знищення. Однак, жоден
        метод передачі даних через Інтернет або метод електронного зберігання не
        може бути абсолютно безпечним.
      </p>
      <p>5. Зберігання інформації</p>
      <p>
        Ми зберігаємо вашу інформацію лише стільки, скільки необхідно для
        досягнення цілей, описаних у цій Політиці, або для дотримання вимог
        законодавства.
      </p>
      <p>6. Ваші права</p>
      <p>Ви маєте право:</p>
      <p>Запитувати доступ до інформації;</p>
      <p>Вимагати виправлення чи видалення ваших даних;</p>
      <p>Відкликати згоду на обробку даних (якщо застосовується);</p>
      <p>Подавати скарги до наглядових органів.</p>
      <p>7. Файли cookie</p>
      <p>
        Ми використовуємо файли cookie та аналогічні технології для збору даних
        про вашу взаємодію з Сервісами. Ви можете керувати налаштуваннями cookie
        через ваш браузер.
      </p>
      <p>8. Зміни у Політиці</p>
      <p>
        Ми залишаємо за собою право оновлювати цю Політику. Зміни набирають
        чинності з моменту опублікування нової версії на нашому сайті. Ми
        рекомендуємо регулярно перевіряти Політику щодо змін.
      </p>
      <p>9. Контакти</p>
      <p>Якщо у вас є запитання чи пропозиції, зв'яжіться з нами:</p>
      <p>Електронна пошта: support@dealine.com.ua</p>
      <p>Телефон: +380960261765</p>
      <p>Дякую, що довіряєте нам!</p>
    </div>
  );
};

export default PolicityAndPrivaty;

// Ассеты
import vector5 from "../assets/images/Vector 5.svg";
import search from "../assets/images/Search.svg";
import frame66 from "../assets/images/Frame 66.svg";
import bVideo from "../assets/video/kiev_small.mp4";
import image2 from "../assets/images/image 2.svg";
import vector4 from "../assets/images/Vector 4.svg";
import rectangle4 from "../assets/images/Rectangle 4.svg";

import spinner from "../assets/icons/spinner-solid.svg";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Carousel from "../components/Carousel";
import ItemSmall from "../components/ItemSmall";
import { useMediaQuery } from "react-responsive";
import BottomSections from "../components/BottomSections";
import { Helmet } from "react-helmet";
const Home = ({ arrayEstates }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const typeSl = "second";
  const dataSl = [
    "Житло вашої мрії – поєднання комфорту, стилю та сучасних технологій. Простір для гармонії і затишку вашої родини в кожній деталі",
    "Створіть дім своєї мрії! Простір, де стиль, комфорт і сучасні рішення створюють ідеальне місце для відпочинку, натхнення та гармонії.",
    "Житло, яке втілює ваші бажання. Де кожен куток створює атмосферу затишку і спокою, ідеальне місце для життя, роботи та відпочинку.",
  ];
  // const data = [

  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  // ];
  const [cityBorough, setBorough] = useState("Весь Київ");
  const [cityType, setType] = useState("Квартира");
  const [cityDeal, setDeal] = useState("Оренда");
  const navigate = useNavigate();

  const handleBoroughChange = (e) => setBorough(e.target.value);
  const handleTypeChange = (e) => setType(e.target.value);
  const handleDealChange = (e) => setDeal(e.target.value);
  const [dataObjects, setDataObjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const sortedEstates = sortByDate(arrayEstates, "property_102");

    if (sortedEstates && sortedEstates.length > 0) {
      setDataObjects(sortedEstates.slice(0, 8));
      setIsLoading(false);
    }
  }, [arrayEstates]);

  function sortByDate(array, dateKey) {
    const months = {
      "січ.": "Jan",
      "лют.": "Feb",
      "бер.": "Mar",
      "квіт.": "Apr",
      "трав.": "May",
      "черв.": "Jun",
      "лип.": "Jul",
      "серп.": "Aug",
      "вер.": "Sep",
      "жовт.": "Oct",
      "лист.": "Nov",
      "груд.": "Dec",
    };

    function parseDate(dateStr) {
      if (!dateStr) return null;
      const [day, month, year] = dateStr.split(" ");
      const formattedDate = `${day} ${months[month]} ${year}`;
      return new Date(formattedDate);
    }

    function findDate(properties, key) {
      const element = properties.find(
        (prop) =>
          prop.property &&
          prop.property.some((p) => p.$ && p.$.attribute === key)
      );
      if (!element) return null;

      const property = element.property.find(
        (p) => p.$ && p.$.attribute === key
      );
      return property ? property._ : null;
    }

    return array.slice().sort((a, b) => {
      const dateA = parseDate(findDate(a.properties, dateKey));
      const dateB = parseDate(findDate(b.properties, dateKey));

      if (!dateA && !dateB) return 0;
      if (!dateA) return 1;
      if (!dateB) return -1;

      return dateB - dateA;
    });
  }

  const putDataObjects =
    !isLoading && dataObjects
      ? dataObjects.map((elem, i) => {
          return <ItemSmall elem={elem} />;
        })
      : null;
  const handleButtonClick = (path) => {
    navigate(`/${path}`);
  };

  return (
    <div>
      {isDesktop && (
        <div className="container-home">
          <Helmet>
            <title>Dealine | Агенство нерухомості | Головна сторінка</title>
            <meta
              name="description"
              content="Головна сторінка, на якиій ви побачите опис та інформацію про квартири або іншу нерухомість, та зможете залишити заявку"
            />
          </Helmet>
          <div id="background-video">
            {" "}
            <video autoPlay muted loop>
              <source src={bVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div id="background-image"></div>
          <section className="section-first">
            <div className="main-title">
              <div className="main-tile-first-block">
                <span className="main-title-text-big">ЖИТЛО</span>
                <p className="main-title-text-small">
                  Житло вашої мрії: комфорт, стиль і затишок. Ідеальне поєднання
                  сучасного дизайну, простору та технологій для створення
                  гармонійного простору вашого життя.
                </p>
              </div>
              <div className="main-tile-second-block">
                <span className="main-title-text-big">ВАШОЇ МРІЇ</span>
                <img
                  src={frame66}
                  alt=""
                  className="main-title-button"
                  onClick={() => handleButtonClick("catalog")}
                />
              </div>
            </div>
            <div className="main-title-search">
              <div className="main-title-select">
                <div class="custom-select">
                  <select value={cityBorough} onChange={handleBoroughChange}>
                    <option value="Весь Київ">Весь Київ</option>
                    <option value="Голосіївський район">
                      Голосіївський район
                    </option>
                    <option value="Дарницький район">Дарницький район</option>
                    <option value="Деснянський район">Деснянський район</option>
                    <option value="Дніпровський район">
                      Дніпровський район
                    </option>
                    <option value="Оболонський район">Оболонський район</option>
                    <option value="Печерський район">Печерський район</option>
                    <option value="Подільський район">Подільський район</option>
                    <option value="Святошинський район">
                      Святошинський район
                    </option>
                    <option value="Солом'янський район">
                      Солом'янський район
                    </option>
                    <option value="Шевченківський район">
                      Шевченківський район
                    </option>
                  </select>
                  <div class="select-arrow"></div>
                </div>
                <div class="custom-select">
                  <select value={cityDeal} onChange={handleDealChange}>
                    <option value="" disabled selected>
                      Оренда/Продаж
                    </option>
                    <option value="option2-1">Оренда</option>
                    <option value="option2-2">Продаж</option>
                  </select>
                  <div class="select-arrow"></div>
                </div>
                <div class="custom-select">
                  <select value={cityType} onChange={handleTypeChange}>
                    <option value="" disabled selected>
                      Тип житла
                    </option>
                    <option value="Квартира">Квартира</option>
                    <option value="Будинок">Будинок</option>
                    <option value="Офіс">Офіс</option>
                  </select>
                  <div class="select-arrow"></div>
                </div>

                <Link
                  className="search-button"
                  to="/catalog"
                  state={{
                    filters: {
                      borough: cityBorough,
                      type: cityType,
                    },
                  }}
                >
                  <img src={search} alt="" />
                </Link>
              </div>
            </div>
            <div className="main-title-bottom">
              <ul>
                <li>
                  01
                  <img src={vector5} alt="" />
                  <p>
                    Житло вашої мрії – поєднання комфорту, стилю та сучасних
                    технологій. Простір для гармонії і затишку вашої родини в
                    кожній деталі.
                  </p>
                </li>
                <li>
                  02
                  <img src={vector5} alt="" />
                  <p>
                    Створіть дім своєї мрії! Простір, де стиль, комфорт і
                    сучасні рішення створюють ідеальне місце для відпочинку,
                    натхнення та гармонії.
                  </p>
                </li>
                <li>
                  03
                  <img src={vector5} alt="" />
                  <p>
                    Житло, яке втілює ваші бажання. Де кожен куток створює
                    атмосферу затишку і спокою, ідеальне місце для життя, роботи
                    та відпочинку.
                  </p>
                </li>
              </ul>
            </div>
          </section>
          <section className="section-second">
            <p>
              <h2>ХТО МИ ТА ЧОМУ ВАРТО ОБРАТИ САМЕ НАС</h2>

              <div>
                <img
                  src={rectangle4}
                  alt=""
                  id="rectagle4"
                  style={{ marginTop: "10px" }}
                />
                <div className="section-second-main-text">
                  Ласкаво просимо до Dealine — вашого надійного партнера у світі
                  нерухомості! Ми працюємо для вас у Києві та пропонуємо повний
                  спектр послуг із нерухомістю: допомагаємо знайти дім мрії,
                  вигідно продати квартиру, будинок чи комерційне приміщення,
                  здати в оренду житло або офіс, а також інвестувати у
                  прибуткові об’єкти. Наші експерти працюють не лише з житловою
                  та комерційною нерухомістю, а й з земельними ділянками — від
                  підбору до оформлення всієї необхідної документації. Наша
                  місія — зробити кожну угоду простою, прозорою та комфортною.
                  Ми завжди на зв’язку, щоб допомогти вам здійснити найкраще
                  рішення на ринку нерухомості Києва!
                  <Link to="/aboutus">
                    ЧИТАТИ ДАЛІ <img src={vector4} alt="" />
                  </Link>
                </div>
              </div>
            </p>
            <img src={image2} className="section-second-img" alt="" />
          </section>
          <section className="section-third">
            <p>
              <h2>НОВІ ОБ'ЄКТИ</h2>
              <Link to="/catalog">
                ПЕРЕГЛЯНУТИ ВСІ <img src={vector4} alt="" />
              </Link>
            </p>
            <div className="objects-on-main">
              {isLoading ? (
                <div className="loading-spinner">
                  {/* Индикатор загрузки */}
                  <img src={spinner} alt="" className="spinner" />
                </div>
              ) : (
                <Carousel
                  data={putDataObjects}
                  type="fourth"
                  buttonTrue={true}
                ></Carousel>
              )}
            </div>
          </section>
          <BottomSections
            sectionFourth={true}
            sectionFive={true}
            sectionReviews={false}
            sectionBottom={true}
            indexOfText="first"
          />
        </div>
      )}
      {isMobile && (
        <div className="container-home-mobile">
          <Helmet>
            <title>Dealine | Агенство нерухомості</title>
            <meta
              name="description"
              content="Головна сторінка, на якиій ви побачите опис та інформацію про квартири або іншу нерухомість, та зможете залишити заявку"
            />
          </Helmet>
          <section className="section-first">
            <div className="main-title">
              <div>
                <div className="main-tile-first-block">
                  <span className="main-title-text-big">ЖИТЛО</span>
                </div>
                <div className="main-tile-second-block">
                  <span className="main-title-text-big">ВАШОЇ МРІЇ</span>
                  <img
                    src={frame66}
                    alt=""
                    className="main-title-button"
                    onClick={() => handleButtonClick("catalog")}
                  />
                </div>
              </div>
              <p className="main-title-text-small">
                Житло вашої мрії: комфорт, стиль і затишок. Ідеальне поєднання
                сучасного дизайну, простору та технологій для створення
                гармонійного простору вашого життя.
              </p>
            </div>
            <div className="main-title-bottom">
              <Carousel data={dataSl} type={typeSl}></Carousel>
              {/* <ul>
                <li>
                  01
                  <img src={vector5} alt="" />
                  <p>
                    Житло вашої мрії – поєднання комфорту, стилю та сучасних
                    технологій. Простір для гармонії і затишку вашої родини в
                    кожній деталі.
                  </p>
                </li>
                <li>
                  02
                  <img src={vector5} alt="" />
                  <p>
                    Створіть дім своєї мрії! Простір, де стиль, комфорт і
                    сучасні рішення створюють ідеальне місце для відпочинку,
                    натхнення та гармонії.
                  </p>
                </li>
                <li>
                  03
                  <img src={vector5} alt="" />
                  <p>
                    Житло, яке втілює ваші бажання. Де кожен куток створює
                    атмосферу затишку і спокою, ідеальне місце для життя, роботи
                    та відпочинку.
                  </p>
                </li>
              </ul> */}
            </div>
            <div className="main-title-search">
              <div className="main-title-select">
                <div>
                  <div class="custom-select">
                    <select value={cityBorough} onChange={handleBoroughChange}>
                      <option value="Весь Київ">Весь Київ</option>
                      <option value="Голосіївський район">
                        Голосіївський район
                      </option>
                      <option value="Дарницький район">Дарницький район</option>
                      <option value="Деснянський район">
                        Деснянський район
                      </option>
                      <option value="Дніпровський район">
                        Дніпровський район
                      </option>
                      <option value="Оболонський район">
                        Оболонський район
                      </option>
                      <option value="Печерський район">Печерський район</option>
                      <option value="Подільський район">
                        Подільський район
                      </option>
                      <option value="Святошинський район">
                        Святошинський район
                      </option>
                      <option value="Солом'янський район">
                        Солом'янський район
                      </option>
                      <option value="Шевченківський район">
                        Шевченківський район
                      </option>
                    </select>
                    <div class="select-arrow"></div>
                  </div>
                  <div class="custom-select">
                    <select value={cityDeal} onChange={handleDealChange}>
                      <option value="" disabled selected>
                        Оренда/Продаж
                      </option>
                      <option value="option2-1">Оренда</option>
                      <option value="option2-2">Продаж</option>
                    </select>
                    <div class="select-arrow"></div>
                  </div>
                  <div class="custom-select">
                    <select value={cityType} onChange={handleTypeChange}>
                      <option value="" disabled selected>
                        Тип житла
                      </option>
                      <option value="Квартира">Квартира</option>
                      <option value="Будинок">Будинок</option>
                      <option value="Офіс">Офіс</option>
                    </select>
                    <div class="select-arrow"></div>
                  </div>

                  <Link
                    className="search-button"
                    to="/catalog"
                    state={{
                      filters: {
                        borough: cityBorough,
                        type: cityType,
                      },
                    }}
                  >
                    <div>
                      <img src={search} alt="" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className="section-second">
            <p>
              <h2>ХТО МИ ТА ЧОМУ ВАРТО ОБРАТИ САМЕ НАС</h2>

              <div>
                <img
                  src={rectangle4}
                  alt=""
                  id="rectagle4"
                  style={{ marginTop: "10px" }}
                />
                <div className="section-second-main-text">
                  Ласкаво просимо до Dealine — вашого надійного партнера у світі
                  нерухомості! Ми працюємо для вас у Києві та пропонуємо повний
                  спектр послуг із нерухомістю: допомагаємо знайти дім мрії,
                  вигідно продати квартиру, будинок чи комерційне приміщення,
                  здати в оренду житло або офіс, а також інвестувати у
                  прибуткові об’єкти. Наші експерти працюють не лише з житловою
                  та комерційною нерухомістю, а й з земельними ділянками — від
                  підбору до оформлення всієї необхідної документації. Наша
                  місія — зробити кожну угоду простою, прозорою та комфортною.
                  Ми завжди на зв’язку, щоб допомогти вам здійснити найкраще
                  рішення на ринку нерухомості Києва!
                  <Link to="/aboutus">
                    ЧИТАТИ ДАЛІ <img src={vector4} alt="" />
                  </Link>
                </div>
              </div>
            </p>
            <img src={image2} className="section-second-img" alt="" />
          </section>
          <section className="section-third">
            <p>
              <h2>НОВІ ОБ'ЄКТИ</h2>
              <Link to="/catalog">
                ПЕРЕГЛЯНУТИ ВСІ{" "}
                <img src={vector4} alt="" style={{ marginLeft: "3px" }} />
              </Link>
            </p>
            <div className="objects-on-main">
              {isLoading ? (
                <div className="loading-spinner">
                  <img src={spinner} alt="" className="spinner" />
                </div>
              ) : (
                <Carousel data={putDataObjects} type="third"></Carousel>
              )}
            </div>
          </section>
          <BottomSections
            sectionFourth={true}
            sectionFive={true}
            sectionBottom={true}
            indexOfText="first"
          />
        </div>
      )}
    </div>
  );
};

export default Home;

import React, { useRef, useState, useEffect } from "react";
import quotes from "../assets/images/quotes.svg";
import vector5 from "../assets/images/Vector 5.svg";
import frame68 from "../assets/icons/Frame 68.svg";
import chevron from "../assets/icons/chevron-right-solid.svg";
// !ACHTUNG CHATGPT
const Carousel = ({ data, type, buttonTrue }) => {
  // const data = { props };
  // const data = [
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  //   {
  //     name: "Name",
  //     surname: "Surname",
  //     type: "Продаж",
  //     review:
  //       "Lorem ipsum dolor sit amet consectetur. Accumsan vitae morbi dignissim proin. Cursus cras quam arcu egestas. Mattis pellentesque tempor non nisl. Cum congue faucibus urna aenean a. Aliquam eu vel volutpat quis quam et tristique et et. Tortor fermentum odio leo eu amet bibendum pharetra.",
  //   },
  // ];
  const carouselRef = useRef(null); // Ссылка на карусель
  const [currentIndex, setCurrentIndex] = useState(0); // Текущий индекс
  const totalItems = data.length; // Количество элементов
  const [startTouch, setStartTouch] = useState(0); // Начальная позиция для свайпа
  const indicatorRef = useRef(null);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  useEffect(() => {
    if (indicatorRef.current) {
      setIndicatorWidth(indicatorRef.current.offsetWidth); // Получаем текущую ширину
    }
  }, [totalItems, currentIndex]); // Этот эффект будет запускаться при изменении totalItems или currentIndex

  // Автоматический таймер для слайдера типа "second"
  useEffect(() => {
    let interval;
    if (type === "second") {
      interval = setInterval(() => {
        setCurrentIndex((prev) => (prev < totalItems - 1 ? prev + 1 : 0));
      }, 3800); // 3 секунды
    }

    return () => {
      if (interval) clearInterval(interval); // Очищаем интервал при размонтировании компонента
    };
  }, [type, totalItems]);

  useEffect(() => {
    const handleResize = () => {
      if (carouselRef.current) {
        // При изменении размера экрана перестраиваем карусель
        carouselRef.current.style.transform = `translateX(-${
          currentIndex * 100
        }%)`; // Сдвигаем карусель на нужное количество
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [currentIndex]);
  // Обработчики для свайпов
  const handleTouchStart = (e) => {
    setStartTouch(e.touches[0].clientX); // Запоминаем начальную позицию
  };

  const handleTouchEnd = (e) => {
    const endTouch = e.changedTouches[0].clientX; // Конечная позиция
    const swipeThreshold = 50; // Порог для определения направления свайпа

    if (startTouch - endTouch > swipeThreshold) {
      // Свайп влево
      handleNext();
    } else if (endTouch - startTouch > swipeThreshold) {
      // Свайп вправо
      handlePrev();
    }
  };
  const handleNext = () => {
    setCurrentIndex((prev) => (prev < totalItems - 1 ? prev + 1 : 0)); // Циклический переход
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : totalItems - 1)); // Циклический переход
  };
  const handleNextBS = () => {
    setCurrentIndex(1);
  };

  const handlePrevBS = () => {
    setCurrentIndex(0);
  };
  const handleIndicatorClick = (index) => {
    setCurrentIndex(index); // Переход по индикатору
  };

  const putArray = data.map((elem, i) => {
    return (
      <div className="carousel-item" key={i}>
        <div className="item-left">
          <h3>
            {elem.name} {elem.surname}
          </h3>
          <p>{elem.type}</p>
        </div>
        <div className="item-right">
          <img src={quotes} alt="" />
          <p>{elem.review}</p>
        </div>
      </div>
    );
  });
  const putArraySecond = data.map((elem, i) => {
    return (
      <div className="carousel-item small-text-variable" key={i}>
        {i + 1}
        <img src={vector5} alt="" />
        <p className="small-text-variable">{elem}</p>
      </div>
    );
  });
  const putArrayThird = data.map((elem, i) => {
    return (
      <div className="carousel-item small-text-variable" key={i}>
        {elem}
      </div>
    );
  });
  function putCaro(type) {
    if (type === "second") {
      return (
        <div className="carousel-type-second">
          <div
            className="carousel"
            ref={carouselRef}
            style={{
              transform: `translateX(-${currentIndex * 100}%)`, // Используем проценты для правильного сдвига
            }}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            {putArraySecond}
          </div>
          <div className="linear"></div>
          <div className="carousel-indicator" ref={indicatorRef}>
            {Array.from({ length: totalItems }).map((_, index) => (
              <div
                style={{ width: `${indicatorWidth / totalItems}px` }}
                key={index}
                className={`indicator-segment ${
                  index === currentIndex ? "active" : ""
                }`}
                onClick={() => handleIndicatorClick(index)} // Переход по индикатору
              ></div>
            ))}
          </div>
        </div>
      );
    } else if (type === "third") {
      return (
        <div className="carousel-type-third">
          <div
            className="carousel"
            ref={carouselRef}
            style={{
              transform: `translateX(-${currentIndex * 100}%)`, // Используем проценты для правильного сдвига
            }}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            {putArrayThird}
          </div>
          <div className="linear"></div>
          <div className="carousel-indicator" ref={indicatorRef}>
            {Array.from({ length: totalItems }).map((_, index) => (
              <div
                style={{ width: `${indicatorWidth / totalItems}px` }}
                key={index}
                className={`indicator-segment ${
                  index === currentIndex ? "active" : ""
                }`}
                onClick={() => handleIndicatorClick(index)} // Переход по индикатору
              ></div>
            ))}
          </div>
        </div>
      );
    } else if (type === "fourth") {
      const lengItem = [1, 2];
      return (
        <div className="carousel-type-fourth">
          <div
            className="carousel"
            ref={carouselRef}
            style={{
              transform: `translateX(-${currentIndex * 100}%)`, // Используем проценты для правильного сдвига
            }}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            {putArrayThird}
          </div>
          <div className="linear"></div>
          <div className="carousel-indicator" ref={indicatorRef}>
            {/* {Array.from({ length: totalItems / 4 }).map((_, index) => ( */}
            {Array.from({ length: totalItems / 4 }).map((_, index) => (
              <div
                style={{
                  width: `${(indicatorWidth / (totalItems / 4)) * 0.1}px`,
                }}
                key={index}
                className={`indicator-segment ${
                  index === currentIndex ? "active" : ""
                }`}
                onClick={() => handleIndicatorClick(index)} // Переход по индикатору
              ></div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div
            className="carousel"
            ref={carouselRef}
            style={{
              transform: `translateX(-${currentIndex * 100}%)`, // Используем проценты для правильного сдвига
            }}
          >
            {putArray}
          </div>
          <div className="linear"></div>
          <div className="carousel-indicator">
            {Array.from({ length: totalItems }).map((_, index) => (
              <div
                key={index}
                className={`indicator-segment ${
                  index === currentIndex ? "active" : ""
                }`}
                onClick={() => handleIndicatorClick(index)} // Переход по индикатору
              ></div>
            ))}
          </div>
        </div>
      );
    }
  }
  return (
    <div className="carousel-container">
      {/* {type === "second" ? (
        <div className="carousel-type-second">
          <div
            className="carousel"
            ref={carouselRef}
            style={{
              transform: `translateX(-${currentIndex * 100}%)`, // Используем проценты для правильного сдвига
            }}
          >
            {putArraySecond}
          </div>
          <div className="linear"></div>
          <div className="carousel-indicator">
            {Array.from({ length: totalItems }).map((_, index) => (
              <div
                key={index}
                className={`indicator-segment ${
                  index === currentIndex ? "active" : ""
                }`}
                onClick={() => handleIndicatorClick(index)} // Переход по индикатору
              ></div>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <div
            className="carousel"
            ref={carouselRef}
            style={{
              transform: `translateX(-${currentIndex * 100}%)`, // Используем проценты для правильного сдвига
            }}
          >
            {putArray}
          </div>
          <div className="linear"></div>
          <div className="carousel-indicator">
            {Array.from({ length: totalItems }).map((_, index) => (
              <div
                key={index}
                className={`indicator-segment ${
                  index === currentIndex ? "active" : ""
                }`}
                onClick={() => handleIndicatorClick(index)} // Переход по индикатору
              ></div>
            ))}
          </div>
        </div>
      )} */}
      {putCaro(type)}
      {buttonTrue && (
        <div className="carousel-button">
          {/* <button className="prev" onClick={handlePrevBS}>
            <img src={chevron} alt="" />
          </button>

          <button className="next" onClick={handleNextBS}>
            <img src={chevron} alt="" />
          </button> */}
        </div>
      )}
    </div>
  );
};

export default Carousel;
